<template>
    <section class="noticias">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mb-4">
                    <div class="titulo-section text-center">
                        <h2>
                            Últimas notícias
                        </h2>
                    </div>
                </div>

                <div class="inicial">
                    <div class="row">
                        <div class="col-lg-6" v-for="(noticia, index) in posts.slice(0, 1)" :key="index">
                            <router-link :to="{ name: 'Leitura Notícia', params: { noticia: noticia.slug } }"
                                aria-label="Ler mais">
                                <div class="thumb-noticia" :style="{ backgroundImage: `url(${noticia.capa})` }">
                                    <div class="box-info">
                                        <div class="data-post">
                                            <span>
                                                {{ noticia.data_publicado }}
                                            </span>
                                        </div>
                                        <div class="titulo-post">
                                            <h5 v-html="tituloTruncado(noticia.titulo)"></h5>
                                        </div>
                                        <div class="ler-mais">
                                            Ler mais
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>

                        <div class="col-lg-6">
                            <div class="row seg-column">
                                <div class="col-lg-6" v-for="(noticia, index) in posts.slice(1, 5)" :key="index">
                                    <router-link :to="{ name: 'Leitura Notícia', params: { noticia: noticia.slug } }"
                                        aria-label="Ler mais">
                                        <div class="thumb-noticia" :style="{ backgroundImage: `url(${noticia.capa})` }">
                                            <div class="box-info">
                                                <div class="data-post">
                                                    <span>
                                                        {{ noticia.data_publicado }}
                                                    </span>
                                                </div>
                                                <div class="titulo-post">
                                                    <h5 v-html="tituloTruncadoMenorColuna(noticia.titulo)"></h5>
                                                </div>
                                                <div class="ler-mais">
                                                    Ler mais
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page" v-if="currentUrl == '/noticias'">
                    <div class="showPosts" v-if="activePost">
                        <div class="row">
                            <div class="col-lg-4 mt-3" v-for="(noticia, index) in posts.slice(5, postsToShow)"
                                :key="index">
                                <router-link :to="{ name: 'Leitura Notícia', params: { noticia: noticia.slug } }"
                                    aria-label="Ler mais">
                                    <div class="thumb-noticia" :style="{ backgroundImage: `url(${noticia.capa})` }">
                                        <div class="box-info">
                                            <div class="data-post">
                                                <span>
                                                    {{ noticia.data_publicado }}
                                                </span>
                                            </div>
                                            <div class="titulo-post">
                                                <h5 v-html="noticia.titulo"></h5>
                                            </div>
                                            <div class="ler-mais">
                                                Ler mais
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" v-if="currentUrl != '/noticias'">
                    <div class="btn-mais-posts text-center">
                        <router-link to="/noticias" aria-label="Mais Posts">
                            Mais posts
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-6" v-if="currentUrl == '/noticias'">
                    <div class="btn-mais-posts text-center">
                        <a href="javascript:;" aria-label="Mais Posts" @click="carregarMais">
                            Carregar mais
                        </a>
                    </div>
                </div>

                <div class="col-lg-6" v-if="currentUrl == '/noticias'">
                    <div class="btn-mais-posts text-center">
                        <a href="javascript:;" aria-label="Menos Posts" @click="carregarMenos">
                            Carregar menos
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

const URLPOSTS = 'https://api.cref20.org.br/wp-json/cref/v1/posts';
export default {
    name: 'NoticiasVue',
    data() {
        return {
            posts: [],
            currentUrl: '',
            activePost: false,
            postsToShow: 5,
            incrementBy: 9
        }
    },
    mounted() {
        axios.get(URLPOSTS).then((response) => (
            this.posts = response.data.map((noticia) => ({
                slug: noticia.slug_post,
                titulo: noticia.titulo_post,
                categoria: noticia.categoria_post,
                capa: noticia.capa_destaque,
                data_publicado: noticia.data_publicacao
            }))
        ))
    },
    created() {
        this.currentUrl = window.location.pathname;
    },
    methods: {
        tituloTruncadoMenorColuna(titulo) {

            if (titulo.length > 33) {
                return titulo.slice(0, 20) + '...';
            } else {
                return titulo;
            }
        },
        tituloTruncado(titulo) {

            if (titulo.length > 60) {
                return titulo.slice(0, 60) + '...';
            } else {
                return titulo;
            }
        },
        carregarMais() {
            this.activePost = true;
            this.postsToShow += this.incrementBy;
        },
        carregarMenos() {
            this.activePost = false;
            this.postsToShow = 5;
        }
    }
}
</script>


<style scoped>
.noticias {
    margin-top: 5rem;
}

.noticias .thumb-noticia {
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    height: 578px;
    padding: 10px;
    position: relative;
}

.noticias .thumb-noticia .categoria {
    background-color: #12575F;
    padding: 10px;
    width: 100px;
    text-align: center;
    border-radius: 50px;
}

.noticias .thumb-noticia .categoria span {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.noticias .thumb-noticia .box-info {
    background: rgba(49, 49, 49, .9);
    padding: 10px;
    color: #fff;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.noticias .thumb-noticia .box-info a {
    color: #fff;
    text-decoration: none;
    border-bottom: 2px solid #45A047;
}

.noticias .seg-column .col-lg-6 {
    margin: 10px 0;
}

.noticias .seg-column .thumb-noticia {
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    height: 275px;
    padding: 10px;
}

.noticias .btn-mais-posts {
    margin-top: 30px;
}

.noticias .btn-mais-posts a {
    border: 2px solid #45A047;
    padding: 15px 30px;
    border-radius: 50px;
    color: #313131;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
}

.noticias .btn-mais-posts a:hover {
    background-color: #45A047;
    color: #fff;
    transition: 0.3s;
}

.noticias .mais-noticias .thumb-noticia {
    height: 321px;
}
</style>