<template>
    <BreadcrumbVue />

    <section class="vagas">
        <div class="container">
            <div class="row">
                <section class="cargos-disponiveis my-5" id="vagas">
                    <div class="container">
                        <h1 class="titulo-cargos mb-5">Confira os cargos disponíveis e cadastre-se</h1>

                        <div v-if="true">
                            <div class="row mt-3 borda-cargo" v-for="(vaga, index) in vagas" :key="index">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <p class="cargo">{{ vaga.cargo }}</p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12 text-lg-end text-md-end">
                                    <a href="javascript:;" class="btn btn-cadastre" onclick="">Cadastre-se</a>
                                </div>
                            </div>
                            <!-- <div class="text-center mt-5">
                                <a href="#" class="btn btn-carregar-mais">Carregar mais</a>
                            </div> -->
                        </div>

                        <div v-else>
                            <h3>Infelizmente ainda não temos vagas cadastradas</h3>
                        </div>


                    </div>
                </section>
            </div>
        </div>
    </section>

    <FooterInternasVue />
</template>



<script>

import axios from 'axios';

const urlVAGAS = 'https://api.cref20.org.br/wp-json/cref/v1/vagas';

import FooterInternasVue from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';


export default {
    name: 'VagasPage',
    components: {
        FooterInternasVue,
        BreadcrumbVue
    },
    data() {
        return {
            vagas: []
        }
    },
    mounted() {
        axios.get(urlVAGAS).then((response) => {
            const data = response.data;
            this.vagas = data.map((vaga) => ({
                id_vagas: vaga.id_vaga,
                slug_vaga: vaga.slug_vaga,
                cargo: vaga.cargo,
                nome_empresa: vaga.nome_empresa
            }))
        });
    },
}
</script>

<style scoped>
.cargos-disponiveis .titulo-cargos {
    color: #212121;
    font-weight: 600;
    margin: 0;
    font-size: 2.2rem;
}

.cargos-disponiveis .cargo {
    font-size: 17px;
    color: #45a047;
    margin: 0;
    font-weight: 700;
}

.cargos-disponiveis .borda-cargo {
    border: solid 1px #E8E8E8;
    display: flex;
    align-items: center;
    padding: 0.6rem 1.5rem;
    border-radius: 6px;
}

.btn-cadastre,
.btn-carregar-mais {
    color: #45a047;
    border: 2px solid #12575f;
    background-color: transparent;
    padding: 0.4rem 2.5rem;
    text-decoration: none;
    font-weight: 700;
    border-radius: 5px;
    font-size: 16px;
}

.btn-cadastre:hover,
.btn-carregar-mais:hover {
    background-color: #45a047;
    color: #fff;
}
</style>