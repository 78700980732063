<template>
    <BreadcrumbVue />
    <section class="eleicoes">
        <div class="container">
            <div class="row mt-4">
                <label for="filtroEleicao">Filtrar por:</label>
                <div class="col-lg-4 mt-2">
                    <select class="form-control" id="filtroEleicao" v-model="filtroSelecionado"
                        @change="atualizarLista">
                        <option v-for="(filtro, index) in filtros" :key="index" :value="filtro">
                            {{ filtro }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 mt-4" v-for="(eleicoes, index) in eleicoesFiltradas" :key="index">
                    <div class="box-eleicoes">
                        <div class="titulo-eleicoes">
                            <h6>
                                {{ eleicoes.ano }}
                            </h6>
                        </div>

                        <div class="nome-eleicoes">
                            <span>
                                {{ eleicoes.titulo }}
                            </span>
                        </div>

                        <div class="link-download mt-4 mb-3" v-if="shouldShowDownloadLink(eleicoes.titulo)">
                            <a :href="eleicoes.arquivo" aria-label="Download de arquivo" target="_blank">
                                Ver Relatório
                                <i class="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>

    <FooterInternas />
</template>

<script>
import axios from 'axios';

const urleleicoes = 'https://api.cref20.org.br/wp-json/cref/v1/eleicoes';

import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '@/components/Breadcrumb/Breadcrumb.vue';
export default {
    name: 'EleicoesPage',
    components: {
        FooterInternas,
        BreadcrumbVue
    },
    data() {
        return {
            eleicoes: [],
            eleicoesFiltradas: [],
            filtros: ['Todos'],
            filtroSelecionado: 'Todos',
        }
    },
    mounted() {
        axios.get(urleleicoes).then((response) => {
            this.eleicoes = response.data[0].arquivos.map((arquivo) => ({
                ano: arquivo.ano_arquivo,
                titulo: arquivo.titulo_arquivo,
                arquivo: arquivo.arquivo
            }))
            this.eleicoesFiltradas = this.eleicoes;
            console.log(this.eleicoesFiltradas);
            this.eleicoes.forEach((eleicao) => {
                if (!this.filtros.includes(eleicao.ano)) {
                    this.filtros.push(eleicao.ano);
                }
            });
        })
    },
    methods: {
        shouldShowDownloadLink(titulo) {
            const titlesToHide = [
                "ANEXO IV - CERTIDÃO NEGATIVA DOS PROCESSOS ÉTICOS",
                "ANEXO V - CERTIDÃO DE REGULARIDADE"
            ];
            return !titlesToHide.includes(titulo);
        },
        atualizarLista() {
            if (this.filtroSelecionado == 'Todos') {
                this.eleicoesFiltradas = this.eleicoes;
            } else {
                this.eleicoesFiltradas = this.eleicoes.filter((eleicao) => {
                    return eleicao.ano == this.filtroSelecionado
                });
            }
        }
    }
}
</script>

<style scoped>
svg {
    width: 100%;
}

.eleicoes .box-eleicoes {
    border: 1px solid #fcd2d2;
    padding: 15px;
    border-radius: 10px;
    height: 193px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.eleicoes .box-eleicoes .titulo-eleicoes h6 {
    color: #45A047;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.eleicoes .box-eleicoes .nome-eleicoes span {
    color: #575757;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase !important;
}

.eleicoes .box-eleicoes .link-download a {
    color: #5e5e5e;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    border-bottom: 1px solid #5D5D5D;
}

.eleicoes .box-eleicoes .link-download a .bi-arrow-right {
    color: #45A047;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}
</style>