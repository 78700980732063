<template>
    <div id="carouselExample" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
        <div class="carousel-inner">
            <div v-for="(display, index) in displays" :key="index" class="carousel-item"
                :class="{ active: index === 0 }">
                <a v-if="isLinkInscricao(display.link)" href="inscricao" @click="goToExternal($event, display.link)">
                    <img :src="display.src" class="d-block w-100" alt="Banner">
                </a>
                <a v-else :href="display.link">
                    <img :src="display.src" class="d-block w-100" alt="Banner">
                </a>
            </div>
        </div>
        <!-- Controles de navegação do Carrossel -->
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>


<script>
import axios from 'axios';

const bannersURL = 'https://api.cref20.org.br/wp-json/cref/v1/displays';
export default {
    name: 'CarrosselVue',
    data() {
        return {
            displays: []
        }
    },
    mounted() {
        axios.get(bannersURL).then((response) => {
            this.displays = response.data.map((item) => ({
                src: item.corpo.display_desktop,
                link: item.corpo.link_de_redirecionamento,
            }));
        });

        this.preloadImg();

    },
    methods: {
        preloadImg() {
            this.displays.forEach((display) => {
                const img = new Image();

                img.src = display.src,
                    img.onload = () => {
                        console.log('Imagem pré-carregada: ', display.src)
                    }
            })
        },
        goToExternal(event, link) {
            event.preventDefault();

            window.open(link, '_blank');
        },
        isLinkInscricao(link) {
            return link.includes('sympla');
        }
    }
}
</script>

<style scoped>
.carousel-inner img {
    height: 100%;
    object-fit: cover;
}

.carousel.slide .carousel-control-prev {
    width: 50px;
    height: 50px;
    background-color: #45A047;
    position: absolute;
    top: 358px;
    left: 30px;
    border-radius: 50px;
}

.carousel.slide .carousel-control-next {
    width: 50px;
    height: 50px;
    background-color: #45A047;
    position: absolute;
    top: 358px;
    right: 30px;
    border-radius: 50px;
}

@media (max-width: 1357px) {

    .carousel-control-prev,
    .carousel-control-next {
        top: 227px !important;
    }
}

@media (max-width: 820px) {
    .carousel-item {
        height: 400px;
    }

    img {
        height: 400px;
        object-fit: cover;
    }

    .carousel-control-prev,
    .carousel-control-next {
        top: 181px !important;
    }

}
</style>