<template>
    <BreadcrumbVue />
    <section class="diretoria">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 my-5">
                    <div class="titulo-page">
                        <h2 v-html="diretoria.titulo_diretoria"></h2>
                    </div>
                </div>

                <!-- <div class="col-lg-4 mt-3" v-for="(diretoria, index) in  diretoria.diretores" :key="index" >
                <div class="box-diretoria">
                    <div class="titulo-diretoria">
                        <h6>
                            {{ diretoria.cargo }}
                        </h6>
                    </div>

                    <div class="nome-funcionario">
                        <span>
                            {{ diretoria.nome }}
                        </span>
                    </div>

                    <div class="numero-cref mt-4 mb-3">
                        <span>
                            {{ diretoria.numero_cref }}
                        </span>
                    </div>
                </div>
            </div> -->

                <img src="../assets/imgs/DIRETORIA.jpg" alt="Componentes da Diretoria">

            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>
    <FooterInternasVue />
</template>

<script>
import axios from 'axios';

const urlDIRETORIA = 'https://api.cref20.org.br/wp-json/cref/v1/diretoria';

import FooterInternasVue from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';
export default {
    name: 'DiretoriaPage',
    components: {
        FooterInternasVue,
        BreadcrumbVue
    },
    data() {
        return {
            diretoria: []
        }
    },
    mounted() {
        axios.get(urlDIRETORIA).then((response) => {
            this.diretoria = {
                titulo_diretoria: response.data[0].corpo.titulo_diretoria,
                diretores: response.data[0].corpo.composicao.map((diretor) => ({
                    cargo: diretor.cargo,
                    nome: diretor.nome,
                    numero_cref: diretor.numero_do_cref
                }))
            };
        });
    }

}
</script>

<style scoped>
span.vermelho {
    color: #45A047 !important;
}

.diretoria .titulo-page h2 {
    color: #313131;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.diretoria .box-diretoria {
    border: 1px solid #fcd2d2;
    padding: 15px;
    border-radius: 10px;
}

.diretoria .box-diretoria .titulo-diretoria h6 {
    color: #12575F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.diretoria .box-diretoria .nome-funcionario span {
    color: #575757;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.diretoria .box-diretoria .numero-cref span {
    background-color: #12575F;
    padding: 10px;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 50px;
}

svg {
    width: 100%;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}
</style>